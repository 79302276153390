"use client";

import { useEffect, useState } from "react";
import { initAOS } from "@/lib/aos";
import { BackgroundGradientAnimation } from "@/components/ui/background-gradient-animation";
import CallToAction from "./CallToAction";

const words = ["Ideating", "Visualizing", "Posting"];

export default function Header() {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    initAOS();

    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000); // Change word every 3 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <BackgroundGradientAnimation>
      <div className="relative z-10 min-h-screen flex flex-col items-center justify-start pt-36 px-8 md:px-16 lg:px-24 text-center">
        <h1
          className="text-3xl md:text-5xl lg:text-6xl font-lightbold mb-4 text-gray-950 max-w-3xl font-inter"
          data-aos="fade-down"
          data-aos-duration="2000"
          data-aos-delay="1000"
        >
          Save Time
          <span className="text-blue-600 transition-opacity duration-500">
            {" "}
            {words[currentWordIndex]}
          </span>
        </h1>
        <p
          className="py-2 text-sm md:text-base lg:text-lg text-gray-600 mb-4 font-inter"
          data-aos="fade-down"
          data-aos-delay="1000"
          data-aos-duration="2000"
        >
          With a Suite of AI Tools At Your Fingertips
        </p>
        <div className="w-full md:w-auto">
          <CallToAction />
        </div>
      </div>
    </BackgroundGradientAnimation>
  );
}
