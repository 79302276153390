"use client";

import { useEffect, useState } from "react";
import { useTheme } from "next-themes";
import { initAOS } from "@/lib/aos";
import Header from "@/components/landing/Header";
import Description from "@/components/landing/Description";
import Modal from "@/components/Modal";
import WaitlistCard from "@/components/waitlist/waitlist-card";
import Footer from "@/components/footer";

export default function Home() {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { setTheme } = useTheme();

  useEffect(() => {
    initAOS({ once: true, disable: "mobile" });
    setTheme("light"); // Force light mode
  }, [setTheme]);

  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);

  return (
    <div className="min-h-screen overflow-x-hidden flex flex-col bg-white text-black">
      <Header />
      <main className="flex-grow w-full">
        {/* Add your landing page content here */}
        {/* <Description /> */}
        {/* Add more sections as needed */}
      </main>
      <Footer />
      {/* <button
        onClick={openModal}
        className="fixed bottom-4 right-4 bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
      >
        Join Waitlist
      </button> */}
      {/* <Modal isOpen={isModalOpen} onClose={closeModal}>
        <WaitlistCard />
      </Modal> */}
    </div>
  );
}
