"use client";

import { useEffect } from "react";
import { SignedOut, SignedIn, useClerk } from "@clerk/nextjs";
import { ArrowRight } from "lucide-react";
import { useRouter } from "next/navigation";
import { initAOS } from "@/lib/aos";

export default function CallToAction() {
  const { openSignUp } = useClerk();
  const router = useRouter();

  useEffect(() => {
    initAOS({ once: true, disable: "mobile" });
  }, []);

  const handleSignUp = () => {
    openSignUp({
      redirectUrl: "/dashboard",
      appearance: {
        elements: {
          rootBox: "w-full h-full bg-gray-100 flex items-center justify-center",
          card: "bg-white shadow-xl rounded-lg",
        },
      },
    });
  };

  const handleDashboardClick = () => {
    router.push("/dashboard");
  };

  return (
    <div className="flex justify-center items-center w-full">
      <SignedOut>
        <button
          onClick={handleSignUp}
          className="group flex justify-center items-center py-3 px-6 text-base font-semibold rounded-lg shadow-md transition-all duration-300 bg-green-500 text-white hover:bg-green-600 hover:scale-105 hover:shadow-lg w-full md:w-auto"
          data-aos="fade-up"
          data-aos-delay="1000"
          data-aos-duration="2000"
        >
          <span className="mr-2">Start Now For Free</span>
          <ArrowRight
            className="transition-transform group-hover:translate-x-1"
            size={20}
          />
        </button>
      </SignedOut>
      <SignedIn>
        <button
          onClick={handleDashboardClick}
          className="group flex justify-center items-center py-3 px-6 text-base font-semibold rounded-lg shadow-md transition-all duration-300 bg-green-500 text-white hover:bg-green-600 hover:scale-105 hover:shadow-lg w-full md:w-auto"
          data-aos="fade-up"
          data-aos-delay="1000"
          data-aos-duration="2000"
        >
          <span className="mr-2">Dashboard</span>
          <ArrowRight
            className="transition-transform group-hover:translate-x-1"
            size={20}
          />
        </button>
      </SignedIn>
    </div>
  );
}
