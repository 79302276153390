import AOS from "aos";
import "aos/dist/aos.css";

export const initAOS = (options = {}) => {
  AOS.init({
    duration: 1000,
    easing: 'ease-out-cubic',
    once: true,
    offset: 50,
    ...options,
  });
};
